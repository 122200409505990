! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

$(document).ready(function($) {
	// Stop close event when clicking on the search form
	$('.header-search-form-2').on('click',function(e) {
		e.stopPropagation();
	});

	// Add active class to link's parent of main navigation
	$('.desktop-navigation-menu .nav-link.active-item').parent().addClass('active-item');

	// COLLAPSIBLE HEADER
	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 1);
	});

	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: [
			"position-right",
			"pagedim-black"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	}, {
		fixedElements: {
			elemInsertMethod: "prependTo"
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-pg-container").fitVids({ ignore: "nofit" });
		$("section.homepage-section.contentsection-odd").fitVids({ ignore: "nofit" });
	}

	// Add dropdown class to main nav items
	$(".nav-dd-toggle:has('.nav-dropdown-list')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".nav-dd-toggle").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".nav-dd-toggle").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}


	// Remove image link's box-shadow
	$('a img').parent().css("box-shadow","none")

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	/**
	 * ----------------------------------------------------------------------
	 * Webflow: Interactions: Init
	 */
	Webflow.require('ix').init([
		{slug: "display-none",name: "Display None",value: {style: {display: "none"},triggers: []}},
		{slug: "logo-load",name: "Logo Load",value: {style: {opacity: 0,x: "0px",y: "-115px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: 300,title: "Wait"},{opacity: 1,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "logo-load-2",name: "Logo Load 2",value: {style: {opacity: 0,x: "0px",y: "-115px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "500ms",title: "Wait"},{opacity: 1,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-text-load-top",name: "Slide Text Load Top",value: {style: {opacity: 0,x: "-50%",y: "-100px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: 800,title: "Wait"},{wait: 500,opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "-50%",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-text-load-top-2",name: "Slide Text Load Top 2",value: {style: {opacity: 0,x: "0px",y: "-25px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1100ms",title: "Wait"},{wait: 500,opacity: 1,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-text-load-top-3",name: "Slide Text Load Top 3",value: {style: {opacity: 0,x: "0px",y: "-25px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1300ms",title: "Wait"},{wait: 500,opacity: 1,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-text-load-bottom",name: "Slide Text Load Bottom",value: {style: {opacity: 0,x: "0px",y: "22px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1500ms",title: "Wait"},{wait: 500,opacity: 1,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "left-nav-load",name: "Left Nav Load",value: {style: {opacity: 0,x: "0px",y: "120px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1000ms",title: "Wait"},{opacity: 1,transition: "transform 600ms ease 0, opacity 700ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "minislideshow-load",name: "MiniSlideshow Load",value: {style: {opacity: 0,x: "0px",y: "200px",z: "0px"},triggers: [{type: "load",stepsA: [{wait: "450ms"},{opacity: 1,transition: "transform 1000ms ease-in-out 0, opacity 1000ms ease-in-out 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "search-dropdown",name: "Search Dropdown",value: {style: {},triggers: []}},
		{slug: "search-cover",name: "Search Cover",value: {style: {},triggers: [{type: "click",selector: ".search-container-cover",stepsA: [{height: "100vh",transition: "height 300ms ease-in-out 0"}],stepsB: []}]}},
		{slug: "close-search",name: "Close Search",value: {style: {},triggers: [{type: "click",selector: ".search-container-cover",stepsA: [{height: "0%",transition: "height 300ms ease 0"}],stepsB: []}]}},
		{slug: "language-toggle",name: "Language Toggle",value: {style: {},triggers: [{type: "click",selector: ".english",stepsA: [{display: "block"}],stepsB: [{display: "none"}]},{type: "click",selector: ".greek",stepsA: [{display: "none"}],stepsB: [{display: "block"}]}]}},
		{slug: "nav-dd-smooth-reveal",name: "Nav DD Smooth Reveal",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "height 350ms ease 0, opacity 350ms ease 0"}],stepsB: [{height: "0px",transition: "height 100ms ease 0, opacity 100ms ease 0"}]}]}},
		{slug: "nav-dd-smooth-reveal-2",name: "Nav DD Smooth Reveal 2",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 1,height: "auto",transition: "height 350ms ease 0, opacity 350ms ease 0"}],stepsB: [{height: "0px",transition: "height 100ms ease 0, opacity 100ms ease 0"}]}]}}
	]);

	/* Disclaimer modal */
	var modal = document.getElementById("dislaimer-modal");
	var xButton = document.getElementById("disclaimer-close-x");

	// When the user clicks on <span> (x), close the modal
	xButton.onclick = function() {
		modal.style.display = "none";
	}

	// When the user clicks anywhere outside of the modal, close it
	window.onclick = function(event) {
		if (event.target == modal) {
			modal.style.display = "none";
		}
	}

	// Fix page height when the page is smaller than the screen height
	if ( $(window).outerHeight() > ( $('body').outerHeight() + $('.nav-container').outerHeight() ) ) {
		missingHeight = $(document).outerHeight() - ($('body').outerHeight() + $('.nav-container').outerHeight())
		pageContentHeight = $('.inside-pg-container').outerHeight()
		$('.inside-pg-container').outerHeight( pageContentHeight + missingHeight )
	}
});

function closeDisclaimerModal() {
	document.getElementById("dislaimer-modal").style.display = "none";
}

function showDisclaimerModal(link) {
	document.getElementById("disclaimer-continue").href = link;
	document.getElementById("dislaimer-modal").style.display = "flex";

	return false;
}
